<script setup>
  const props = defineProps({
    classes: String
  })
</script>

<template>
  <svg :class="props.classes" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.0798 13.2797C51.1198 13.2797 51.9198 14.0797 51.9198 15.1197C51.9198 16.1597 51.1198 16.9597 50.0798 16.9597H40.2398C39.1998 16.9597 38.3998 16.1597 38.3998 15.1197C38.3998 14.0797 39.1998 13.2797 40.2398 13.2797H50.0798ZM50.0798 11.6797H40.2398C38.3198 11.6797 36.7998 13.1997 36.7998 15.1197C36.7998 17.0397 38.3198 18.5597 40.2398 18.5597H50.0798C51.9998 18.5597 53.5198 17.0397 53.5198 15.1197C53.5198 13.1997 51.9198 11.6797 50.0798 11.6797Z" fill="#000"/>
    <path d="M60.1602 19.0391L68.1602 25.4391" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M44.8799 45.8402L56.6399 32.2402" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M23.1201 28.5605H38.1601" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.04 34.4805H38.48" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M11.52 40.2402H38.48" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M45.12 48.3191C46.578 48.3191 47.76 47.1371 47.76 45.6791C47.76 44.221 46.578 43.0391 45.12 43.0391C43.662 43.0391 42.48 44.221 42.48 45.6791C42.48 47.1371 43.662 48.3191 45.12 48.3191Z" fill="#000" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M42.48 21.8405V18.5605" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M48.0801 21.8405V18.5605" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M60.3198 27.2002L64.1598 22.2402" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.0401 24.16C37.3601 23.04 40.8801 21.76 45.2801 22C55.5201 22.48 68.5601 31.12 68.5601 45.2C68.5601 57.92 57.8401 68.16 45.2801 68.32C32.6401 68.4 21.9201 58.08 21.6001 45.2" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>

</template>
