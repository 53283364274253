<script setup>
import GainMileage from "~/components/Vector/GainMileage.vue";
import GainProcess from "~/components/Vector/GainProcess.vue";
import GainAgreement from "~/components/Vector/GainAgreement.vue";
import GainResell from "~/components/Vector/GainResell.vue";

const breakpoint = useBreakpoints();

const gains = [
  {
    title: 'Szybki proces',
    text: 'Decyzja leasingowa w 15 minut, jedna prosta umowa na wszystkie usługi.',
    icon: GainProcess
  },
  {
    title: 'Brak limitu kilometrów',
    text: 'Niezależnie od sposobu zakończenia umowy, nie obciążymy Cię opłatami za nadprzebieg.',
    icon: GainMileage
  },
  {
    title: 'Elastyczne zakończenie umowy',
    text: 'Samochód możesz wymienić na nowy, wykupić na własność, przedłużyć finansowanie lub po prostu go oddać.',
    icon: GainAgreement
  },
  {
    title: 'Gwarancja zwrotu',
    text: 'Możliwość przedterminowego zakończenia umowy, bez podawania przyczyny, z prostym rozliczeniem i bez opłat karnych.',
    icon: GainResell
  },
]

</script>

<template>
  <div>
    <div class="md:grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
      <template v-for="(gain,index) in gains">
        <div class="px-6 py-4 flex"
             :class="{'md:border-r md:border-r-gray-400': !(index % 2)}">
          <div class="mr-4">
            <Component :is="gain.icon" :classes="'w-15 h-15'"/>
          </div>
          <div>
            <div class="font-normal text-base text-black w-full mb-2">{{ gain.title }}</div>
            <div class="font-extralight text-black w-full">{{ gain.text }}</div>
          </div>
        </div>

        <div v-if="!((index+1) % 2) && index + 1< gains.length" class="col-span-2 md:border-t md:border-t-gray-400"></div>
      </template>
    </div>
  </div>
</template>
