<script setup>
  const props = defineProps({
    classes: String
  })
</script>

<template>
  <svg :class="props.classes" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6001 13.9999L59.2801 13.9199" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M68.0003 22.3999L59.2803 13.9199" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M59.2803 30.8004L68.0003 22.4004" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M19.6802 30.7207L59.2802 30.8007" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M19.6001 14L19.6801 30.72" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M60.4002 54.2422H20.7202" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M12 45.8418L20.72 54.2418" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.72 37.4414L12 45.8414" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M60.3202 37.4414H20.7202" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M60.3998 54.2414L60.3198 37.4414" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M39.04 54.2402V71.2002" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M38.6401 37.4409V30.9609" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M38.6401 13.6008V8.80078" stroke="#000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>

</template>
